.balanceDetails {
    background-color:bisque;
}
.MuiSvgIcon-root {
    margin-bottom: -3px;
    height: 17px;
    width: 24px;
}

.switch-to-goerli {
    background-color: pink;
    padding: 5px 10px; 
    margin: 0px auto;
    width: 300px;
}

.switch-network-container {
    max-width: 300px;
    margin: 0px auto;
}

/* override css for material ui */
.crypto-icon {
    margin: 0px 5px 0px 5px;
    width: 20px !important;
    height: 20px !important;
    margin-right: 5px;
    display: inline-block;
    background-image: url("https://wolvercoin.com/logo192.png");
}
