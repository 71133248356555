.contribute {
    padding: 0 30%;
}

.readableContent {
    border-radius: 10px;
    background-color:antiquewhite;
    padding: 5px;
    border: 1px solid black;
    margin: 10px auto;
    text-align: center;
}

.nftAddition {
    font-size: small;
}

.error {
    color: red;
    font-weight: bold;
}
