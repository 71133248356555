.admin {
    color: antiquewhite;
    text-align: center;
    padding: 0 30%;
}

.result {
    color: red;
    font-size: 15px;
}

.preview-image {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

#add-admin-form, #add-public-good-form, #add-auction-item-form {
    color: black;
    padding: 20px;
    background-color: antiquewhite;
    border-radius: 10px;
    margin: 20px;
}