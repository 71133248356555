.auction {
    color: antiquewhite;
    text-align: center;
}
.auction ul {
    display: inline-block;
}

#items_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.item {
    aspect-ratio: 1 / 1;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    background-color: #00000099;
    color: white;
    text-shadow: 1px 1px 4px black;
    border-radius: 7px;
    box-shadow: 0px 0px 10px 0px black;
    border: white 1px solid;
    padding: 10px;
}
.item button {
    background-color: #00FF00;
    color: white;
    text-shadow: 1px 1px 4px black;
    font-size: 20px;
    border-radius: 3px;
    border: none;
    padding: 5px 10px;
    text-transform: uppercase;
}
.item button:hover {
    background-color: #99FF99;
    cursor: pointer;
}

/* CSS to make a flashing red border around the item when the auction is about to end */
.alert-to-approve-spending {
    animation: alert-to-approve-spending 1s infinite;
    padding: 20px;
    font-weight: bold;
    background-color: #550000;
    border-radius: 10px;
}
@keyframes alert-to-approve-spending {
    0% {
        border: 3px solid white;
    }
    50% {
        border: 3px solid red;
    }
    100% {
        border: 3px solid white;
    }
}

/* button to click for approval, should be deep red and black colored */
.button-approve-spending {
    color: black;
    text-shadow: 1px 1px 1px black;
    font-size: 20px;
    border-radius: 3px;
    border: 2px solid black;
    padding: 5px 10px;
    text-transform: uppercase;
}

.button-approve-spending:hover {
    background-color: #FF0000;
    cursor: pointer;
}
.button-approve-spending:active {
    background-color: #550000;
}

.approved-to-spend {
    color: #009900;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 17px;
    border-radius: 3px;
    padding: 5px 10px;
    text-transform: uppercase;
    width: 600px;
    margin: 0px auto;
}
