.public_goods {
    color: antiquewhite;
    text-align: center;
}
.public_goods ul {
    display: inline-block;
}

#goods_list {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.good {
    aspect-ratio: 1 / 1;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    background-color: #00000099;
    color: white;
    text-shadow: 1px 1px 4px black;
    border-radius: 7px;
    box-shadow: 0px 0px 10px 0px black;
    border: white 1px solid;
    padding: 10px;
}
.good button {
    background-color: #00FF00;
    color: white;
    text-shadow: 1px 1px 4px black;
    font-size: 20px;
    border-radius: 3px;
    border: none;
    padding: 5px 10px;
    text-transform: uppercase;
}
.good button:hover {
    background-color: #99FF99;
    cursor: pointer;
}

.good button.negative {
    background-color: #FF0000;
}
.good button.negative:hover {
    background-color: #FF9999;
}

.good input {
    font-size: 20px;
    width: 70px;
}